<template>
  <div class="page">
    <Tables
      ref="xTable"
      :tableName="tableName"
      :tableData="tableData"
      :totalPage="totalPage"
      :isLoading="isLoading"
      :searchFormData="searchFormData"
      :showRefresh="true"
      :showSearch="true"
      @clearSearch="clearSearch"
      @getList="getList"
    >

      <!--搜索自定义内容-->
      <el-form-item slot="search-item">
        <el-input
          v-model="searchFormData.phone"
          size="small"
          clearable
          placeholder="按账号查询"
        ></el-input>
      </el-form-item>
      <!--表格内容-->
      <vxe-table-column
              slot="table-item"
              field="qy_name"
              title="企业名称"
              align="center"
              min-width="100px"
      />
      <vxe-table-column
              slot="table-item"
              field="phone"
              title="账号"
              align="center"
              min-width="100px"
      />
      <vxe-table-column
        slot="table-item"
        field="js"
        title="角色"
        align="center"
        min-width="100px"
      />
      <vxe-table-column
              slot="table-item"
              field="electric"
              title="账户电量"
              align="center"
              min-width="50px"
      />
      <vxe-table-column slot="table-item" field="create_time" title="加入时间" align="center" min-width="80px"/>
      <vxe-table-column
        slot="table-item"
        title="操作"
        align="center"
        width="400px"
      >
        <template v-slot="{ row }">
          <el-button
            size="small"
            plain
            @click="editOut(row)"
            v-if="$hasAccess('market.qycard/outele')"
          >分配电量</el-button>
          <el-button
                  size="small"
                  plain
                  @click="editMem(row)"
                  v-if="$hasAccess('market.qycard/outlog')"
          >分配历史</el-button>
          <el-button
            size="small"
            plain
            @click="deleteOne(row)"
            v-if="$hasAccess('market.qycard/delmem')"
          >删除</el-button>
        </template>
      </vxe-table-column>
    </Tables>

    <!-- 分配电量 -->
    <el-dialog
            title="分配电量"
            :visible.sync="outDialogShow"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            width="500px"
    >
      <el-form
              :model="outDialogFormData"
              :rules="rules"
              ref="editForm"
              label-width="100px"
              class="form"
      >
        <el-form-item label="可分配电量" prop="remark">
          <el-input
                  style="width: 50%;"
                  disabled
                  v-model="outDialogFormData.fp_ele" clearable />
        </el-form-item>
        <el-form-item label="电量" prop="electric">
          <el-input-number
                  :step="100"
                  v-model="outDialogFormData.electric" clearable />
        </el-form-item>
      </el-form>
      <div
              slot="footer"
              class="dialog-footer"
      >
        <el-button
                type="primary"
                size="small"
                plain
                icon="el-icon-check"
                @click="saveOut()"
                :disabled="editDisabled"
                v-if="$hasAccess('market.qycard/outele')"
        >提 交
        </el-button>
        <el-button
                size="small"
                plain
                icon="el-icon-close"
                @click="closeDialog"
        >取 消
        </el-button>
      </div>
    </el-dialog>
    <!-- 分配历史 -->
    <el-dialog
            title="分配历史"
            :visible.sync="editDialogShow"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            width="760px"
    ><template>
      <el-table  :data="this.detailCb" border class="data_table">
        <!--表格内容-->
        <el-table-column prop="phone" label="账号" width="180"></el-table-column>
        <el-table-column prop="qy_name" label="所属企业" width="180"></el-table-column>
        <el-table-column prop="electric" label="分配电量" width="180"></el-table-column>
        <el-table-column prop="create_time" label="分配时间" width="180"></el-table-column>
      </el-table>
    </template>
    </el-dialog>
  </div>
</template>

<script>
import Tables from '@/components/tables'
export default {
  name: 'market-coupon-list',
  components: {
    Tables
  },
  data () {
    return {
      // 表格信息
      tableName: '充值优惠',
      isLoading: false,
      tableData: [],
      totalPage: 0,
      qy_id: '',
      detailCb:[],
      searchFormData: {},
      groupList: [],
      groupIds: [],
      // 新增
      addDialogShow: false,
      addDialogFormData: {},
      groupLoading:false,
      rules: {
        qy_name: [
          { required: true, message: '请输入企业名称', trigger: 'change' }
        ],
        electric: [
          { required: true, message: '请输入电量', trigger: 'change' }
        ]
      },
      // 编辑
      editDialogShow: false,
      outDialogShow:false,
      memDialogShow:false,
      editDialogFormData: {},
      outDialogFormData: {},
      memDialogFormData:{},
      editDialogFormOriData: {},
      editNeedData: ['coupon_id', 'coupon_name', 'coupon_money', 'coupon_type','type','grouptype','group_id', 'gift_money']
    }
  },
  computed: {
    editDisabled () {
      for (let item of this.editNeedData) {
        if (this.editDialogFormData[item] != this.editDialogFormOriData[item]) {
          return false
        }
      }
      return false
    }
  },
  mounted () {
    this.init();
  },
  activated () {
    this.init();
  },
  methods: {
    // 初始化请求
    init() {
      if (this.$route.query.qy_id) {
        if (this.qy_id != this.$route.query.qy_id) {
          this.qy_id = this.$route.query.qy_id;
          this.getList();
        }
      } else {
        this.$confirm('当前页面数据失效，请返回项目列表重新打开', '提示', {
          cancelButtonClass: 'btn-custom-cancel',
          confirmButtonText: '确定',
          showCancelButton: false,
          showClose: false,
          closeOnClickModal: false,
          type: 'warning'
        }).then(() => {
          this.closeTab()
        })
      }
    },
    // 表格列表
    async getList (currentPage, pageSize) {
      if (!this.qy_id) {
        return;
      }
      const params = {
        token: this.$store.state.user.token,
        page: currentPage,
        size: pageSize,
        qy_id:this.qy_id
      }
      this.isLoading = true
      const searchData = this.searchFormData
      const res = await this.$api.Market.GetQydetailList(params, searchData)
      this.tableData = res.data
      this.totalPage = res.total
      this.isLoading = false
    },
    // 重置搜索
    clearSearch () {
      this.searchFormData = {}
    },
    // 重置form
    resetFormData () {
      this.addDialogFormData = {

      }
      this.groupIds = [];
      this.editDialogFormData = {}
      this.editDialogFormOriData = {}
      this.outDialogFormData = {}
      if (this.$refs.addForm) {
        this.$refs.addForm.resetFields()
      }
      if (this.$refs.editForm) {
        this.$refs.editForm.resetFields()
      }
    },
    // 新增
    addOne () {
      this.resetFormData()
      this.addDialogShow = true
    },
    //分配电量
    async editOut (row) {
      this.resetFormData();
      this.outDialogFormData.qy_id=row.qy_id;
      this.outDialogFormData.id=row.id;
      this.outDialogFormData.fp_ele=row.fp_electric;
      this.outDialogShow = true
    },
    //分配历史
    async editMem (row) {
      this.editDialogShow = true
      const params = {
        token: this.$store.state.user.token,
        son_id: row.son_id,
        qy_id:row.qy_id
      }
      const res = await this.$api.Market.GetQylog(params)
      this.detailCb = res
    },
    // 删除
    deleteOne (row) {
      this.$confirm('确定要删除该成员吗', '提示', {
        cancelButtonClass: 'btn-custom-cancel',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const params = {
          token: this.$store.state.user.token,
          id: row.id
        }
        await this.$api.Market.DeleteMem(params)
        this.$notify({
          title: '成功',
          message: '删除成功',
          type: 'success'
        })
        this.$refs.xTable.refreshTable()
      })
    },
    // 关闭弹窗
    closeDialog () {
      this.addDialogShow = false
      this.editDialogShow = false
      this.outDialogShow = false
      this.memDialogShow = false
    },
    // 新增保存
    saveAdd () {
      this.$refs.addForm.validate(async valid => {
        if (valid) {
          // if (this.addDialogFormData.select_time!=undefined) {
          //   this.addDialogFormData.start_time = parseInt(this.addDialogFormData.select_time.getTime() / 1000);
          //   this.addDialogFormData.end_time = parseInt(this.addDialogFormData.select_time2.getTime() / 1000);
          // }
          const params = {
            token: this.$store.state.user.token,
            form: JSON.stringify(this.addDialogFormData)
          }
          await this.$api.Market.AddQy(params)
          this.$notify({
            title: '成功',
            message: '新增成功',
            type: 'success'
          })
          this.closeDialog()
          this.$refs.xTable.refreshTable()
        }
      })
    },
    // 编辑保存
    saveEdit () {
      this.editDialogFormData.group_id = this.groupIds.join(',')
      this.$refs.editForm.validate(async valid => {
        if (valid) {
          if (this.editDialogFormData.coupon_type == 20) {
            this.selecttime.start_time = this.editDialogFormData.start_time.length == undefined ? parseInt(this.editDialogFormData.start_time.getTime() / 1000) : this.editDialogFormData.start_time;
            this.selecttime.end_time = this.editDialogFormData.end_time.length == undefined ? parseInt(this.editDialogFormData.end_time.getTime() / 1000) : this.editDialogFormData.end_time;
            this.editDialogFormData.start = this.selecttime.start_time;
            this.editDialogFormData.end = this.selecttime.end_time;
          }
          const params = {
            token: this.$store.state.user.token,
            form: JSON.stringify(this.editDialogFormData)
          }
          await this.$api.Market.EditCoupon(params)
          this.$notify({
            title: '成功',
            message: '编辑成功',
            type: 'success'
          })
          this.closeDialog()
          this.$refs.xTable.refreshTable()
        }
      })
    },
    // 分配电量
    saveOut () {
      this.$refs.editForm.validate(async valid => {
        if (valid) {
          const params = {
            token: this.$store.state.user.token,
            form: JSON.stringify(this.outDialogFormData)
          }
          await this.$api.Market.Qyoutele(params)
          this.$notify({
            title: '成功',
            message: '提交成功',
            type: 'success'
          })
          this.closeDialog()
          this.$refs.xTable.refreshTable()
        }
      })
    },
    // 添加成员
    saveMem () {
      this.$refs.editForm.validate(async valid => {
        if (valid) {
          const params = {
            token: this.$store.state.user.token,
            form: JSON.stringify(this.memDialogFormData)
          }
          await this.$api.Market.QyMem(params)
          this.$notify({
            title: '成功',
            message: '提交成功',
            type: 'success'
          })
          this.closeDialog()
          this.$refs.xTable.refreshTable()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
